import React from "react";
import { Text } from "@chakra-ui/layout";

export function TermDefinition({ term }) {
  if (!term || !term.sq) {
    return null;
  }

  return (
    <p>
      <dfn>
        <Text as="span" fontWeight="bold">
          {term.sq}
        </Text>
      </dfn>{" "}
      - {term.en}
    </p>
  );
}
