import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Text } from "@chakra-ui/layout";

export function Letters({ letters = [] }) {
  const data = useStaticQuery(graphql`
    query {
      allDictionarySqJson {
        nodes {
          en
          slug
          sq
        }
      }
    }
  `);

  const words = data.allDictionarySqJson.nodes;

  return (
    <Breadcrumb
      mt={5}
      mb={5}
      w={{ base: "100%", md: "80%", lg: "70%" }}
      fontSize={{ base: "12px", md: "14px" }}
      separator=" "
      textAlign="center"
    >
      {letters.map((letter) => {
        const firstWord = words.find((w) =>
          w.sq.toLowerCase().startsWith(letter)
        );

        if (firstWord && firstWord.slug) {
          return (
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`/fjalori/${firstWord ? firstWord.slug : ""}`}
              >
                {letter.toUpperCase()}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        } else {
          return (
            <BreadcrumbItem>
              <Text color="gray" cursor="pointer">
                {letter.toUpperCase()}
              </Text>
            </BreadcrumbItem>
          );
        }
      })}
    </Breadcrumb>
  );
}
