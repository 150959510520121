import React from "react";
import { Box, Text, Heading, Center, Divider } from "@chakra-ui/layout";
// @ts-ignore
import * as styles from "../../styles/ContractsJson.module.css";
import { SearchBar } from "./search-bar";
import { Letters } from "./letters";
import { TermDefinition } from "./term-definition";
import { RelatedTerms } from "./related-terms";

export const letters = [
  "a",
  "b",
  "c",
  "ç",
  "d",
  "dh",
  "e",
  "ë",
  "f",
  "g",
  "gj",
  "h",
  "i",
  "j",
  "k",
  "l",
  "ll",
  "m",
  "n",
  "nj",
  "o",
  "p",
  "q",
  "r",
  "rr",
  "s",
  "sh",
  "t",
  "t",
  "u",
  "v",
  "x",
  "xh",
  "y",
  "z",
  "zh",
];

export function Dictionary({ startsWith = "", term = { sq: "" } }) {
  return (
    <Box my={10}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
      >
        <Text as={"span"}>Fjalorth terminologjik për drejtësinë</Text>
      </Heading>

      {/* <ContractBreadcrumb contract={contract} /> */}

      <Box className={styles.flex}>
        <Box className={styles.content}>
          <Box m={10}>
            <SearchBar selectedWord={term.sq} />

            <Center>
              <Letters letters={letters}></Letters>
            </Center>

            <Divider m={5} />

            <TermDefinition term={term} />
          </Box>
        </Box>
        <Box className={styles.aside}>
          <RelatedTerms startsWith={startsWith} />
        </Box>
      </Box>
    </Box>
  );
}
