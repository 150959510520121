import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../../layouts";
import { Stack, Divider } from "@chakra-ui/layout";
import { Header } from "../../components/header";

export function RelatedTerms({ startsWith = "" }) {
  const data = useStaticQuery(graphql`
    query {
      allDictionarySqJson {
        nodes {
          en
          slug
          sq
        }
      }
    }
  `);

  const words = data.allDictionarySqJson.nodes;

  return (
    <>
      <Header.H4>
        Fjalë që fillojnë me <b>{startsWith.toUpperCase()}</b>
      </Header.H4>
      <Divider my={5} />
      <Stack>
        <Stack>
          {words
            .filter((i) => i.sq.toLowerCase().startsWith(startsWith))
            .map((item) => (
              <Link to={`/fjalori/${item.slug}`}>{item.sq}</Link>
            ))}
        </Stack>
      </Stack>
    </>
  );
}
