import React, { Fragment, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../layouts";
import { Dictionary } from "../../components/dictionary";

const DictionaryPageWithResults = (props) => {
  const definition = props.data.dictionarySqJson;
  const startsWith = definition.sq[0].toLowerCase();

  return (
    <>
      <Layout location={props.location}>
        <Dictionary startsWith={startsWith} term={definition} />
      </Layout>
    </>
  );
};

export default DictionaryPageWithResults;

export const pageQuery = graphql`
  query ($slug: String!) {
    dictionarySqJson(slug: { eq: $slug }) {
      id
      sq
      en
      slug
    }
  }
`;
