import React, { Fragment, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { Box, Text, Stack, Divider } from "@chakra-ui/layout";
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";

export function SearchBar({ selectedWord = "" }) {
  const data = useStaticQuery(graphql`
    query {
      allDictionarySqJson {
        nodes {
          en
          slug
          sq
        }
      }
    }
  `);

  const words = data.allDictionarySqJson.nodes;

  const [term, setTerm] = useState(selectedWord);
  const [isSearching, setSearching] = useState(false);
  const results = words
    .filter((w) => w.sq.toLowerCase().includes(term.toLowerCase()))
    .slice(0, 10);

  return (
    <Fragment>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          type="text"
          // onBlur={() => setSearching(false)}
          onFocus={() => setSearching(true)}
          placeholder="Kërko"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />
        {isSearching && (
          <InputRightElement
            children={<DeleteIcon color="legit.500" />}
            onClick={() => {
              setSearching(false);
              setTerm("");
            }}
          />
        )}
      </InputGroup>

      {isSearching && term.length >= 3 && results.length > 0 ? (
        <Stack
          marginTop={2}
          marginBottom={2}
          boxShadow="sm"
          p="6"
          rounded="md"
          bg="white"
          border="1px"
          borderColor="gray.200"
          divider={<Divider />}
          onClick={(event) => {
            event.preventDefault();
            setSearching(true);
          }}
        >
          {results.map((word) => {
            return <Link to={`/fjalori/${word.slug}`}>{word.sq}</Link>;
          })}
        </Stack>
      ) : isSearching && term.length >= 3 && results.length == 0 ? (
        <Box
          marginTop={2}
          marginBottom={2}
          boxShadow="sm"
          p="6"
          rounded="md"
          bg="white"
          border="1px"
          borderColor="gray.200"
        >
          <Text>Nuk ka asnjë rezultat për termin "{term}"</Text>
        </Box>
      ) : null}
    </Fragment>
  );
}
